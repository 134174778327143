<template>
    <div class="rich-text">
        <div v-if="toolbar" class="expand-toolbar">
            <div v-for="item in fontStyle" :key="item.icon" class="icon-box" :class="{ active: item.active }" @click="toggleActiveStyle(item)">
                <i class="iconfont" v-html="item.icon"></i>
            </div>
            <div class="decorate"></div>
            <Emoji class="emoji" :tinymce-id="tinymceId" />
            <RichTextImg class="rich-text-img" :tinymce-id="tinymceId" />
        </div>
        <vue-tinymce :value="value" :init="config" @input="handler" :id="tinymceId"></vue-tinymce>
    </div>
</template>

<script>
import tinymce from "tinymce"
import VueTinymce from "@tinymce/tinymce-vue"
import Emoji from "./Emoji.vue"
import RichTextImg from "./RichTextImg.vue"
let _this;
export default {
    name: 'RichText',
    components: {
        VueTinymce,
        Emoji,
        RichTextImg
    },
    props: {
        value: {
            type: String,
            default: ""
        },
        tinymceId: {
            type: String,
            default: ""
        },
        toolbar: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fontStyle: [
                { icon: "&#xe6ef;", active: false, command: "Italic" },
                { icon: "&#xe71d;", active: false, command: "Bold" },
                { icon: "&#xe65f;", active: false, command: "underline" }
            ],
            config: {
                menubar: false,
                toolbar: false,
                statusbar: false,
                height: 157,
                max_height: 157,
                br_in_pre: false,
                language: 'zh_CN', //本地化设置
                content_style: "body { margin: 10px; } p { margin: 0; }",
                custom_ui_selector: ".emoji",
                setup: (editor) => {
                    editor.on("keydown", e => {
                        const isEnter = ["Enter", "NumpadEnter"].includes(e.code);
                        const keyUp = e.code == "ArrowUp";
                        const keyDown = e.code == "ArrowDown";
                        if(!isEnter && !keyUp && !keyDown) return void 0;
                        e.preventDefault();
                        isEnter && this.$emit("customEnter");
                        keyUp && this.$emit("customKeyUp");
                        keyDown && this.$emit("customKeyDown");
                    });
                    editor.on("focus", e => {
                        this.$emit("customFocus")
                    })
                }
            }
        }
    },
    computed: {},
    methods: {
        handler(value) {
            this.$nextTick(() => {
                const richText = tinymce.get(this.tinymceId);
                if(!richText) return;
                this.$emit("update:value", richText.getContent());
            })
        },
        toggleActiveStyle(item) {
            item.active = !item.active;
            const richText = tinymce.get(this.tinymceId);
            richText.execCommand(item.command, false, item.active)
        }
    },
    created() {
        _this = this;
    }
}
</script>

<style scoped lang="scss">
.rich-text {
    flex: 1;
    .expand-toolbar {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 10px;
        background-color: #F5F5F5;
        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            margin-left: 3px;
            cursor: pointer;
            color: #666666;
        }
        .icon-box {
            border-radius: 4px;
            transition: all .3s;
            &:hover {
                background-color: #eeeddd;
            }
            &:nth-child(1) .iconfont {
                font-size: 18px;
            }
            &:nth-child(2) .iconfont {
                font-size: 14px;
            }
            &:nth-child(3) .iconfont {
                font-size: 18px;
            }
            &.active {
                background-color: #ddd;
            }
        }
        
        .decorate {
            width: 1px;
            height: 17px;
            background-color: #999999;
        }
    }
}
::v-deep p {
    margin: 0;
}
::v-deep .tox-tinymce {
    border: none !important;
}
::v-deep .expand-toolbar {
    .iconfont,
    .el-icon-picture {
        color: #666666;
    }
}
/* ::v-deep .tox-editor-header {
    height: 40px !important;
    border-bottom: none !important;
    background-color: #F5F5F5 !important;
} */
</style>
