<template>
    <div class="chat-window">
        <div class="info-bar">
            <div class="username" v-if="currentRobot">{{ currentRobot.name }}</div>
            <div class="response-time">
                <!-- 响应时间: {{ answerStatus.beBing ? currentRobot.chatConfig.responseTime : 0 }}S -->
            </div>
            
            <div class="status">
                <el-button  @click="gotoblank"  style="margin-right:10px;line-height: 7px;">任务详情</el-button>
                <el-button v-if="answerStatus.status == 2" type="primary" class="btn-start">{{ answerType }}结束</el-button>
                <el-button  v-else-if="!answerStatus.beBing" type="primary" class="btn-start" @click="startAnswer">
                    <span v-if="answerStatus.status == 0">开始{{ answerType }}</span>
                    <span v-else-if="answerStatus.status == 3 || answerStatus.status == 1">继续{{ answerType }}</span>
                </el-button>
                <div v-else-if="answerStatus.beBing" class="answering">{{ answerType }}中...</div>
            </div>
        </div>
        <div class="main-window">
            <template v-if="currentRobot">
                <el-scrollbar style="height: 100%;" ref="elScroll">
                    <div class="group-list">
                        <div class="group-item" v-for="(item, index) in currentRobot.chatConfig.template" :key="index">
                            <div class="robot">
                                <div class="avatar" style="margin-right: 16px;" :class="{ finished: currentRobot.chatConfig.finished }">
                                    <img class="avatar-img" :src="currentRobot.avatar" :alt="currentRobot.name" :title="currentRobot.name">
                                </div>
                                <div class="content">
                                    <div class="message">{{ item.question }}</div>
                                    <div class="time">{{ item.robot_ask_time }}</div>
                                </div>
                            </div>
                            <div class="student" v-if="item.stu_key_word || currentRobot.chatConfig.finished">
                                <div class="content">
                                    <div class="message" :class="{ clear: [1, 3, 4].includes(item.stu_key_word.score_operate) }">
                                        <div v-if="item.stu_key_word && typeof item.stu_key_word == 'string'" v-html="item.stu_key_word"></div>
                                        <div v-else-if="item.stu_key_word.score_operate == 2">订单已直赔</div>
                                        <Coupon class="coupon" v-else-if="item.stu_key_word.score_operate == 1" :coupon="item.stu_key_word.coupon_info" :status="true" />
                                        <Coupon class="coupon" v-else-if="item.stu_key_word.score_operate == 4" :coupon="item.stu_key_word" :status="true" />
                                        <Goods class="goods" v-else-if="item.stu_key_word.score_operate == 3" :goods="item.stu_key_word.goodInfo" />
                                        <div v-else-if="item.stu_key_word.score_operate == 5" class="check-order">
                                            <div>亲，请您核对订单信息 ({{ item.stu_key_word.order_sn }})</div>
                                            <Goods class="goods" style="margin: 10px 0;" :goods="item.stu_key_word.goodInfo" />
                                            <div class="name">姓名：{{ item.stu_key_word.recipients }}</div>
                                            <div class="phone">电话：{{ item.stu_key_word.contact }}</div>
                                            <div class="address">地址：{{ item.stu_key_word.address }}</div>
                                        </div>
                                        <div v-else-if="currentRobot.chatConfig.finished && !item.stu_answer_time && !item.stu_key_word" class="timeout">超时未作答</div>
                                    </div>
                                    <div class="time">{{ item.stu_answer_time ? item.stu_answer_time : "已超时" }}</div>
                                </div>
                                <div class="avatar" style="margin-left: 16px;">
                                    <img class="avatar-img" :src="userInfo.avatar" :alt="userInfo.username" :title="userInfo.username" @error="imgError" >
                                </div>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </template>
        </div>
        <div class="input-textarea">
            <RichText 
                v-if="currentRobot" 
                :toolbar="true" 
                tinymce-id="tinymceChat" 
                :value.sync="currentRobot.chatConfig.value"
                @customEnter="sendMsg"
                @customKeyUp="replyKeyChange('up')"
                @customKeyDown="replyKeyChange('down')"
            />
            <div class="reply-list" v-if="replyVisible" tabindex="1" ref="replyList">
                <el-scrollbar style="flex: 1;">
                    <div v-for="(item, index) in viewReplyList" class="reply-item" :id="`reply-item${index}`" :class="{ select: replyIndex == index }" :key="item.id" v-html="item.content" :title="item.content" @click="clickReply(index)"></div>
                </el-scrollbar>
            </div>
            <div class="mask" v-if="!currentRobot || currentRobot.chatConfig.finished" @click="disabledAnswerHint"></div>
        </div>
        <div class="bottom-btn">
            <el-button class="send-chat-btn" :class="{ disabled: !answerStatus.beBing || currentRobot.chatConfig.finished }" type="primary" @click="sendMsg" :disabled="!answerStatus.beBing || currentRobot.chatConfig.finished">发送/Enter</el-button>
        </div>
    </div>
</template>

<script>
import RichText from "./common/RichText.vue"
import Coupon from "./common/Coupon.vue"
import Goods from "./common/GoodsItem.vue"
export default {
    name: 'ChatWindow',
    
    components: {
        RichText,
        Coupon,
        Goods
    },
    props: {
        currentRobot: {
            type: Object,
            default: () => {}
        },
        responseTime: {
            type: Number,
            default: 0
        },
        answerStatus: {
            type: Object,
            default: () => {}
        },
        userInfo: {
            type: Object,
            default: () => {}
        },
        replyList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            replyVisible: false,
            replyIndex: 0,
            matchChar: "",
            answerType: localStorage.getItem("type") == 1 ? "考试" : "训练",
            comp_type:localStorage.getItem("type"),
            start_flag:localStorage.getItem('startFlag')?localStorage.getItem('startFlag'):false
        }
    },
    inject: ["formatTime", "isDev"],
    computed: {
        viewReplyList() {
            if(!this.replyVisible || this.replyList.length <= 0) return [];
            return this.replyList.reduce((list, item) => list.concat(item.children),[]).filter(item => item.fast_coding.includes(this.matchChar));
        },
    },
    methods: {
        gotoblank(){
            this.start_flag=true;
            localStorage.setItem("startFlag",JSON.stringify(this.start_flag))
            const routeOne = this.$router.resolve({
             path: '/student/kefutaskdetail',
            })
           window.open(routeOne.href, '_blank')
        },
        startAnswer() {
            if([1,3].includes(this.answerStatus.status)){
                this.$emit("startAnswer")
                return
            }
            if(!this.start_flag){
                this.$message({
                    type:'warning',
                    dangerouslyUseHTMLString: true,
                    message: '<span>请先查看 "任务详情" 后再开始考试!</span>'
                });
                return false
            }
            this.$emit("startAnswer")
        },
        sendMsg() {
            if(this.replyVisible) {
                const item = this.viewReplyList[this.replyIndex];
                this.$emit("setChatMessage", item.content.replace("[图片]", item.imgTag));
                this.replyVisible = false;
                this.matchChar = "";
                return void 0;
            }
            this.currentRobot.chatConfig.sendMsg(this.currentRobot.chatConfig.value);
        },
        imgError(e) {
            e.target.src = this.currentRobot.avatar;
        },
        bottomOut() {
            this.$nextTick(() => {
                this.$refs.elScroll.$refs.wrap.scrollTop = this.$refs.elScroll.$refs.wrap.scrollHeight;
            });
        },
        replyKeyChange(type) {
            const { replyIndex } = this;
            if(type == 'up') {
                this.replyIndex = replyIndex <= 0 ? this.viewReplyList.length - 1 : replyIndex - 1;
            }else {
                this.replyIndex = replyIndex >= this.viewReplyList.length - 1 ? 0 : replyIndex + 1;
            }
            const targetEl = document.getElementById(`reply-item${this.replyIndex}`);
            targetEl && targetEl.scrollIntoView();
        },
        clickReply(index) {
            this.replyIndex = index;
            this.sendMsg();
        },
        disabledAnswerHint() {
            if(this.currentRobot && this.currentRobot.chatConfig.finished) {
                this.$message.warning("机器人已下线无法作答");
            }else if(!this.answerStatus.beBing) {
                this.$message.warning("请先开始考试");
            }
        }
    },
    watch: {
        "currentRobot.chatConfig.value"(newVal, oldVal) {
            const test = /\/#(.*?)</.exec(this.currentRobot.chatConfig.value);
            if(!test) {
                this.replyVisible = false;
                return void 0;
            };
            if(test[1] === "") {
                this.replyVisible = true;
            }else {
                this.matchChar = test[1];
            }
        },
        "viewReplyList.length"(newVal, oldVal) {
            if(newVal != oldVal) {
                this.replyIndex = 0;
            }
        }
    },
    created() {}
}
</script>
<style scoped lang="scss">
$themeColor: #006AFF;
.chat-window {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
    border: 1px solid #EAECF0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #fff;
    /* overflow: hidden; */
    .info-bar {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 68px;
        padding: 0 26px 0 16px;
        color: #1E2226;
        .status {
            width: 192px;
            height: 30px;
            border-radius: 4px;
            font-size: 12px;
            display: flex;
            .center {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                font-size: 12px;
            }
            .btn-start {
                @extend .center;
                color: #fff;
                background-color: #006AFF;
                border: none;
            }
            .answering {
                @extend .center;
                background-color: #DEEBFF;
                color: #0C6AFF;
            }
        }
    }
    .main-window {
        box-sizing: border-box;
        flex: 69;
        background-color: #f0edf1;
        overflow: hidden;
        .group-list {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 20px;
        }
        .group-item {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            .robot,
            .student {
                display: flex;
                font-size: 12px;
                color: #696970;
                .content {
                    width: fit-content;
                    max-width: 80%;
                    overflow: hidden;
                    word-break: break-word;
                    .message {
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        max-width: 100%;
                        min-height: 62px;
                        padding: 20px;
                        margin-bottom: 10px;
                        font-size: 16px;
                        color: #1E2226;
                        border-radius: 8px;
                        background-color: #fff;
                        overflow: hidden;
                        &.clear {
                            padding: 0;
                            background-color: transparent;
                        }
                        .goods {
                            max-width: 320px;
                        }
                        .coupon {
                            width: 340px;
                        }
                        .check-order {
                            padding-right: 54px;
                        }
                    }
                }
                .avatar {
                    width: 51px;
                    height: 51px;
                    border-radius: 50%;
                    overflow: hidden;
                    .avatar-img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .robot {
                margin-bottom: 30px;
                .finished {
                    filter: grayscale(1);
                }
            }
            .student {
                display: flex;
                justify-content: flex-end;
                .content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    .message {
                        width: fit-content;
                        background-color: #0478FF;
                        color: #fff;
                    }
                    .time {
                        text-align: end;
                    }
                }
            }
            
        }
    }
    .input-textarea {
        position: relative;
        flex: 19;
        .mask {
            display: block !important;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
        .reply-list {
            display: flex;
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            right: 20%;
            max-width: 150px;
            max-height: 80%;
            border-radius: 4px;
            /* border: 1px solid #aaa; */
            background-color: #f0edf1;
            overflow: hidden;
            .reply-item {
                box-sizing: border-box;
                width: 100%;
                max-width: 100%;
                padding: 5px 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                transition: all .3s;
                cursor: pointer;
                &.select {
                    background-color: $themeColor;
                    color: #fff;
                }
                &:hover {
                    background-color: $themeColor;
                    color: #fff;
                }
            }
        }
    }
    .bottom-btn {
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        flex: 3.6;
        padding: 16px;
        .send-chat-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 36px;
            letter-spacing: 1px;
            background-color: $themeColor;
            border: none;
            &:hover {
                background-color: #368aff;
            }
            &.disabled {
                background-color: #E1E1E1;
                color: #fff;
            }
        }
    }
}
::v-deep p {
    display: inline;
    margin: 0;
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    margin-bottom: 0 !important;
}
::v-deep .goods-item {
    background-color: #fff;
}
</style>
